import { IEmptyData } from '~/types/common'
import { PATH_IMAGES } from '~/config/constant'
export const PATH_ICON = '/assets/images/icon/'
export const NEWS = {
  title: 'Tin Tức',
  titleDetail: 'Chi tiết tin tức',
  titleRelated: 'Tin tức liên quan',
  iconDate: `${PATH_ICON}clock.svg`,
  categoryLocalStoreKey: 'LIST-CATEGORY-NEWS',
  categoryId: 'categoryId',
  categoryNameKey: 'category-name',
  pathCategory: '/tin-tuc/the-loai/',
  pathDetailPage: 'tin-tuc-alias',
  pathNews: '/tin-tuc',
  breadcrumb: [
    {
      alias: 'all',
      link: '/tin-tuc',
      name: 'Tin tức'
    }
  ]
}

export const EMPTY_DATA: IEmptyData = {
  icon: `${PATH_IMAGES}icon-empty.webp`,
  text: 'Dữ liệu đang cập nhật',
  subText: 'Quý khách vui lòng quay lại sau'
}
